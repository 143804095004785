* {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

*::selection {
  background: #c0c1c5;
}

body {
  font-size: 62.5%;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0b0c10;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Nunito-Regular.ttf");
}

@font-face {
  font-family: "Nunito";
  font-style: bold;
  font-weight: 700;
  src: url("./assets/fonts/Nunito-Bold.ttf");
}

@font-face {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/FiraCode-Regular.woff2"),
    url("./assets/fonts/FiraCode-Regular.woff"),
    url("./assets/fonts/FiraCode-Regular.ttf");
}
